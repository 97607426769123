.Contact a {
  color: lightskyblue;
  text-decoration: underline;
}
.Contact a:visited {
  color: lightskyblue;
  text-decoration: underline;
}
.Contact h1 {
  margin-top: -2.5%;
}