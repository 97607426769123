body {
  background-color: #0B0B30;
  background-repeat:  no-repeat;
  background-attachment: fixed;
  color: lightgrey;
}
input[type="text"], textarea {    
  background-color : purple;
  color: orange;
  border:0px;    
}
h1 {
	background-color: #0B0B30;
	border-radius: 4px;
	color: lightskyblue;
	text-align: center;
	justify-content: left;
	margin-left: 0px;
	margin-top: -1%;
	margin-bottom: 0%;
	padding-bottom: 4px;
}
h2 {
	justify-content: right;

}
h3 {
	justify-content: right;

}
p{
	justify-content: right;
	padding-left: 5%;
	padding-right: 5%;
	padding-bottom: 1%;
}