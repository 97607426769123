ul {
  display: flex;
  list-style-type: none;
  height:  10%;
  width: 100%;
  position: static;
  padding: 0%;
  margin-top: 1px;
  justify-content: center;
}

li {
  text-align: left;
  display: fixed;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}
button {
  display: block;
  font-size: 30px;
  background-color: grey;
  color: lightskyblue;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
button:hover {
  background-image: radial-gradient(yellow 5%, green 50%, #0B0B30);
  transition: 1s;
  transform: scale(1.2,1.2);
  text-shadow: 2px 2px 4px #000000;
  box-shadow: 10px 10px 5px black;
}