.projecttemplate {
	border-style: solid;
	border-color: black;
	border-radius: 10px;
	width: 20%;
	background-color: grey;
	text-align: left;
	padding: 1px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
}
.projecttemplate:hover{
	transition: 1.5s;
	transform: scale(1.05,1.05);
	background-image: radial-gradient(yellow 15%, green 60%);
	text-shadow: 10px 10px 10px pink;

}
img {
	width: 100%;
	height: 50%;
}

h2 {
	height: 8%;
	padding-bottom: 2%;
}

p{
	font-size: 15px;
	padding: 1%;
}
a:link{
	color: black;
	text-decoration: none;
}
a:visited{
	color: black;
	text-decoration: none;
}